/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 PRO React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/
import HaveIBeenTrained from "pages/Creatives/HaveIBeenTrained";
import AiTxt from "pages/Creatives/AiTxt";
import CreativesFaq from "pages/Creatives/CreativesFaq";
import Kudurru from "pages/Creatives/Kudurru";
import BrowserExtension from "pages/Creatives/BrowserExtension";
import DataDiligence from "pages/Developers/DataDiligence";
import SpawningApi from "pages/Developers/SpawningApi";
import DevelopersFaq from "pages/Developers/DevelopersFaq";
import About from "pages/Company/About";

const routes = [
  {
    name: "Creatives",
    columns: 2,
    rowsPerColumn: 1,
    collapse: [
      {
        name: "Products",
        collapse: [
          {
            name: "Have I Been Trained?",
            route: "/have-i-been-trained",
            component: <HaveIBeenTrained />,
          },
          {
            name: "Kudurru",
            route: "/kudurru",
            component: <Kudurru />,
          },
          {
            name: "Browser Extension",
            route: "/browser-extension",
            component: <BrowserExtension />,
          },
          {
            name: "Ai.txt",
            route: "/ai-txt",
            component: <AiTxt />,
          },
        ],
      },
      {
        name: "Resources",
        collapse: [
          {
            name: "Creatives FAQ",
            route: "/creatives-faq",
            component: <CreativesFaq />,
          },
        ],
      },
    ],
  },
  {
    name: "Developers",
    columns: 2,
    rowsPerColumn: 1,
    collapse: [
      {
        name: "Products",
        collapse: [
          {
            name: "Spawning API",
            route: "/spawning-api",
            component: <SpawningApi />,
          },
          {
            name: "Data Diligence",
            route: "/data-diligence",
            component: <DataDiligence />,
          },
        ],
      },
      {
        name: "Resources",
        collapse: [
          {
            name: "API Docs \u21D7",
            href: "https://datadiligence.readthedocs.io/en/latest/quickstart.html",
          },
          {
            name: "Developers FAQ",
            route: "/developers-faq",
            component: <DevelopersFaq />,
          },
        ],
      },
    ],
  },
  {
    name: "Company",
    // icon: <Icon>article</Icon>,
    collapse: [
      {
        name: "About",
        route: "/about",
        component: <About />,
      },
      {
        name: "Blog \u21D7",
        href: "https://spawning.substack.com",
      },
    ],
  },
];

export default routes;
