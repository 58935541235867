// Sections
import Header from "sections/Header";
import SectionTitle from "sections/SectionTitle";
import ProductsGrid from "sections/CardGrid";
import CardList from "sections/CardList";
import Tdm from "sections/Tdm";

// Components
import SEO from "components/SEO";
import MKBox from "components/MKBox";

// Images
import bgImage from "assets/images/headers/optimized/home.jpg";
import bgImageSm from "assets/images/headers/preloads/home.jpg";

function Spawning() {
  return (
    <>
      <SEO />
      <Header
        image={bgImage}
        preview={bgImageSm}
        title="Data Governance for Generative AI"
        subtitle="Quality | Safety | Consent"
        links={[
          {
            link: "#content",
            linkText: "learn more",
            linkExt: false,
          },
        ]}
      />
      <MKBox width="100%" id="content" pt={8} mt={-4}>
        <SectionTitle pretitle="Products For" title="Creatives" />
        <ProductsGrid
          cols={3}
          cardType="ColoredBg"
          cards={[
            {
              label: "Search and Discover",
              title: "Have I Been Trained",
              description:
                "Search for your work in popular AI datasets and add it to our Do Not Train registry.",
              action: {
                type: "internal",
                route: "/have-i-been-trained",
                route: "/have-i-been-trained",
                label: "get started",
              },
            },
            {
              // image: cardImage,
              label: "Protect Your Website",
              title: "Kudurru",
              description: "Block active AI web scrapers from accessing your website's content.",
              action: {
                type: "internal",
                route: "/kudurru",
                label: "get started",
              },
            },
            {
              // image: cardImage,
              label: "Opt Out Anything",
              title: "Spawning Browser Extension",
              description: "Opt out any media, anywhere it appears on the web.",
              action: {
                type: "internal",
                route: "/browser-extension",
                route: "/browser-extension",
                label: "get started",
              },
            },
          ]}
        />
        <SectionTitle pretitle="Products For" title="Developers" />
        <CardList
          card={{
            // image: cardImage,
            title: "Data Diligence",
            label: "Open Source Python Package",
            description:
              "Respect creator opt-outs in your model training by adding only a few lines of code.",
            action: {
              type: "internal",
              route: "/data-diligence",
              route: "/data-diligence",
              label: "get started",
            },
          }}
          list={[
            {
              title: "Respect all Machine Readable Opt-Outs",
              description:
                "Consolidates opt-outs from the Spawning API, ai.txt, tdmrep.json, DeviantArt's NoAI headers, and more.",
            },
            {
              title: "Integrated with img2dataset",
              description:
                "Our fork of img2dataset is a simple way to download from text-to-image datasets.",
            },
            {
              title: "Easy to use",
              description: "Check out our documentation to learn more about how easy it can be!",
            },
          ]}
          swap={false}
        />
        <CardList
          card={{
            // image: cardImage,
            title: "Spawning API",
            label: "Secure | Fast | Up to Date",
            description:
              "Our API serves opt-out information for 1.5 Billion URLs (and counting) without slowing down your model training.",
            action: {
              type: "internal",
              route: "/spawning-api",
              route: "/spawning-api",
              label: "get started",
            },
          }}
          list={[
            {
              title: "Secure",
              description:
                "API requests only return opt-out flags for known links and don't reveal the creator's information.",
            },
            {
              title: "Fast",
              description:
                "Our API returns flags for 10k images per request and scales to meet the most demanding training runs.",
            },
            {
              title: "Up to date",
              description:
                "We scan for known opt-out methods and cache them with our API. Never miss a permission change for training data.",
            },
          ]}
          swap={true}
        />
        <Tdm />
      </MKBox>
    </>
  );
}

export default Spawning;
