import React, { useState } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import PropTypes from "prop-types";

const Toggle = ({ label, defaultChecked = false, ...props }) => {
  const [checked, setChecked] = useState(defaultChecked || false);

  if (label) {
    return (
      <FormControlLabel
        control={
          <Switch
            sx={{
              marginRight: "8px",
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") setChecked(!checked);
            }}
            onChange={() => setChecked(!checked)}
            {...props}
          />
        }
        label={label}
      />
    );
  }

  return (
    <Switch
      checked={checked}
      onKeyDown={(e) => {
        if (e.key === "Enter") setChecked(!checked);
      }}
      onChange={() => setChecked(!checked)}
      {...props}
    />
  );
};

Toggle.propTypes = {
  label: PropTypes.string,
  defaultChecked: PropTypes.bool,
};

export default Toggle;
