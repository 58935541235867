// Sections
import Header from "sections/Header";
import SectionTitle from "sections/SectionTitle";
import Team from "sections/Team";
import Logos from "sections/Logos";
import News from "sections/News";
import Faq from "sections/Faq";

// Components
import SEO from "components/SEO";
import MKBox from "components/MKBox";

// Images
import bgImage from "assets/images/headers/optimized/about.jpg";
import bgImageSm from "assets/images/headers/preloads/about.jpg";
import jordanImage from "assets/images/team/jordan.png";
import matImage from "assets/images/team/mat.png";
import patrickImage from "assets/images/team/patrick.png";
import hollyImage from "assets/images/team/holly.png";
import nickImage from "assets/images/team/nick.png";
import cullenImage from "assets/images/team/cullen.png";

function About() {
  return (
    <>
      <SEO
        title="About Spawning"
        description="We believe that a future of consenting data will benefit both AI development and the people it is trained on."
      />
      <Header
        image={bgImage}
        preview={bgImageSm}
        title="About Spawning"
        subtitle="We believe that a future of consenting data will benefit both AI development and the people it is trained on."
        links={[
          {
            link: "#content",
            linkText: "learn more",
            linkExt: false,
          },
        ]}
      />
      <MKBox width="100%" id="content" pt={8} mt={-4}>
        <SectionTitle pretitle="spawning" title="Latest News" />
        <News />
        <SectionTitle pretitle="spawning" title="Leadership Team" />
        <Team
          cards={[
            {
              image: jordanImage,
              name: "Jordan Meyer",
              position: "Cofounder/CEO",
            },
            {
              image: patrickImage,
              name: "Patrick Hoepner",
              position: "Cofounder/CTO",
            },
            {
              image: matImage,
              name: "Mat Dryhurst",
              position: "Cofounder",
            },
            {
              image: hollyImage,
              name: "Holly Herndon",
              position: "Cofounder",
            },
            {
              image: nickImage,
              name: "Nicholas Padgett",
              position: "VP of Engineering",
            },
            {
              image: cullenImage,
              name: "Cullen Miller",
              position: "VP of Policy",
            },
          ]}
        />
        <SectionTitle pretitle="spawning" title="Our Partners" />
        <Logos />
        <SectionTitle pretitle="spawning" title="Frequently Asked Questions" />
        <Faq
          questions={[
            {
              question: "What does Spawning mean?",
              answer:
                "Spawning is a term we created to describe the act of creating entirely new media with an AI system trained on older media. We felt it was necessary to distinguish that this process is different from older techniques like sampling or collage.",
            },
            {
              question: "What is Spawning's relationship with organizations that train AI models?",
              answer:
                "Spawning is an independent third party that created a Do Not Train registry intended to provide <a href='https://copyrightblog.kluweriplaw.com/2019/07/24/the-new-copyright-directive-text-and-data-mining-articles-3-and-4/' target='_blank;'>machine readable opt-outs</a> to AI model trainers. We have partnered with two organizations (Stability and Hugging Face), who have agreed to honor the Do Not Train registry. We are actively working to partner with more organizations to ensure the widest adoption of respect for creatives' wishes.",
            },
            {
              question: "Doesn't opt-out protect AI companies over artists?",
              answer:
                "We believe that requiring the informed consent of the creatives whose work is used to train AI is the ideal solution. For now, with most AI companies scraping the web without any consideration for creatives, we see respecting opt-outs as a practical step forward. We do not want creatives to be forced to opt out. We want creatives to have the option to opt out.",
            },
          ]}
        />
      </MKBox>
    </>
  );
}

export default About;
