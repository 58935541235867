import React from "react";

const VideoIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="#CFD5FF" />
    <g clipPath="url(#clip0_475_4673)">
      <path
        d="M20 28.3334V11.6667M15.8333 28.3334V24.1667M15.8333 15.8334V11.6667M24.1666 28.3334V24.1667M24.1666 15.8334V11.6667M11.6666 15.8334H28.3333M11.6666 24.1667H28.3333M28.3333 24.3334V15.6667C28.3333 14.2666 28.3333 13.5666 28.0608 13.0318C27.8211 12.5614 27.4387 12.1789 26.9683 11.9392C26.4335 11.6667 25.7334 11.6667 24.3333 11.6667L15.6666 11.6667C14.2665 11.6667 13.5664 11.6667 13.0316 11.9392C12.5612 12.1789 12.1788 12.5614 11.9391 13.0318C11.6666 13.5666 11.6666 14.2666 11.6666 15.6667L11.6666 24.3334C11.6666 25.7335 11.6666 26.4336 11.9391 26.9684C12.1788 27.4388 12.5612 27.8212 13.0317 28.0609C13.5664 28.3334 14.2665 28.3334 15.6666 28.3334H24.3333C25.7334 28.3334 26.4335 28.3334 26.9683 28.0609C27.4387 27.8212 27.8211 27.4388 28.0608 26.9684C28.3333 26.4336 28.3333 25.7335 28.3333 24.3334Z"
        stroke="#707070"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_475_4673">
        <rect width="20" height="20" fill="white" transform="translate(10 10)" />
      </clipPath>
    </defs>
  </svg>
);

export default VideoIcon;
