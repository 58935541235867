import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

const SEO = ({ card, description, image, image_alt, name, title, type, url, username }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={image} />
    <meta property="og:image:alt" content={image_alt} />
    <meta property="og:site_name" content={name} />
    <meta property="og:type" content={type} />
    <meta property="og:title" content={title} />
    <meta property="og:url" content={url} />
    <meta name="twitter:card" content={card} />
    <meta name="twitter:creator" content={username} />
    <meta name="twitter:image" content={image} />
    <meta name="twitter:site" content={username} />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
  </Helmet>
);

// Setting default values for the props of InfoBackgroundCard
SEO.defaultProps = {
  card: "summary_large_image",
  description: "Data Governance for Generative AI",
  image: "https://a-us.storyblok.com/f/1012441/800x557/d5836cd512/symbol.png",
  image_alt: "Spawning Logo",
  name: "spawning.ai",
  title: "Spawning AI",
  type: "website",
  url: "https://www.spawning.ai",
  username: "@spawning_",
};

SEO.propTypes = {
  card: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  image_alt: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
};

export default SEO;
