import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

import bgImage from "assets/images/headers/optimized/home.jpg";
import bgImageSm from "assets/images/headers/preloads/home.jpg";

import { Launch } from "@mui/icons-material";

function Header({ image, preview, title, subtitle, links, half, logoImage }) {
  const [currentImage, setCurrentImage] = useState(preview);

  useEffect(() => {
    // Check if the device is mobile based on the window width
    const isMobile = window.innerWidth <= 768;

    // Only load the full-size image if not on a mobile device
    if (!isMobile) {
      const fullImage = new Image();
      fullImage.src = image;
      fullImage.onload = () => setCurrentImage(image);
    }
  }, [image]);

  return (
    <>
      {half ? (
        <MKBox
          minHeight="50vh"
          width="100%"
          margin="0"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.5),
                rgba(gradients.dark.state, 0.5)
              )}, url(${currentImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "end start",
          }}
        >
          <Container width="100%">
            <Grid
              container
              item
              xs={12}
              justifyContent={{ xs: "center", md: "start" }}
              sx={{ textAlign: { xs: "center", md: "left" } }}
            >
              <MKTypography
                variant="h1xl"
                color="white"
                mb={4}
                aria-label="heading"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                {title}
              </MKTypography>
              {logoImage && (
                <MKBox
                  component="img"
                  sx={{ width: "50px", height: "50px", margin: "10px" }}
                  src={logoImage}
                  alt="Logo"
                />
              )}
            </Grid>
          </Container>
        </MKBox>
      ) : (
        <MKBox
          minHeight="100vh"
          width="100%"
          margin="0"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.7),
                rgba(gradients.dark.state, 0.7)
              )}, url(${currentImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "end start",
          }}
        >
          <Container width="100%">
            <Grid
              container
              item
              xs={12}
              justifyContent={{ xs: "center", md: "start" }}
              sx={{ textAlign: { xs: "center", md: "left" } }}
            >
              <MKTypography
                variant="h1xl"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
                role="heading"
              >
                {title}
              </MKTypography>
              <MKTypography
                variant="body1"
                color="white"
                width="100%"
                mt={1}
                pr={{ md: 12, lg: 12, xl: 12 }}
                opacity={0.9}
                aria-label="subheading"
                role="subheading"
              >
                {subtitle}
              </MKTypography>
              <Stack direction="row" spacing={1} mt={6} mb={8}>
                {links.map(({ link, linkText, linkExt }, key) => (
                  <MKButton
                    key={link}
                    variant="outlined"
                    color="white"
                    mr={key === links.length - 1 ? 0 : 3}
                    mb={3}
                    href={link}
                    target={linkExt ? "_blank" : "_self"}
                  >
                    {linkText}
                    {linkExt ? <Launch /> : null}
                  </MKButton>
                ))}
              </Stack>
            </Grid>
          </Container>
        </MKBox>
      )}
    </>
  );
}

// Setting default values for the props of HeaderHalf
Header.defaultProps = {
  image: bgImage,
  preview: bgImageSm,
  title: "Default Title",
  subtitle: "Default Subtitle",
  links: [],
  half: false,
};

// Typechecking props for the HeaderHalf
Header.propTypes = {
  image: PropTypes.string.isRequired,
  preview: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  links: PropTypes.array,
  half: PropTypes.bool,
};

export default Header;
