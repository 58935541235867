// Sections
import Header from "sections/Header";
import SectionTitle from "sections/SectionTitle";
import ContactForm from "sections/ContactForm";

// Images
import bgImage from "assets/images/headers/optimized/kudurru.jpg";
import bgImageSm from "assets/images/headers/preloads/kudurru.jpg";

function Contact() {
  return (
    <>
      <Header image={bgImage} preview={bgImageSm} half title="Contact Us" />
      <SectionTitle title="How can we help you?" />
      <ContactForm />
    </>
  );
}

export default Contact;
