import React from "react";

const AudioIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="#CFD5FF" />
    <g clipPath="url(#clip0_250_6772)">
      <path
        d="M29 26V20C29 15.0294 24.9706 11 20 11C15.0294 11 11 15.0294 11 20V26M13.5 29C12.1193 29 11 27.8807 11 26.5V24.5C11 23.1193 12.1193 22 13.5 22C14.8807 22 16 23.1193 16 24.5V26.5C16 27.8807 14.8807 29 13.5 29ZM26.5 29C25.1193 29 24 27.8807 24 26.5V24.5C24 23.1193 25.1193 22 26.5 22C27.8807 22 29 23.1193 29 24.5V26.5C29 27.8807 27.8807 29 26.5 29Z"
        stroke="#707070"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_250_6772">
        <rect width="20" height="20" fill="white" transform="translate(10 10)" />
      </clipPath>
    </defs>
  </svg>
);

export default AudioIcon;
