import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import allTutorials from "../../data/tutorials.json"; // Import the JSON file containing all tutorials
import StoryblokClient from "storyblok-js-client";

// Sections
import Header from "sections/Header";

// Images
import bgImage from "assets/images/headers/optimized/kudurru.jpg";
import bgImageSm from "assets/images/headers/preloads/kudurru.jpg";
import Container from "@mui/material/Container";
import MKBox from "components/MKBox";
import YoutubeEmbed from "components/YoutubeEmbed";
import MKTypography from "components/MKTypography";
import "../../styles/Tutorials.css";

const Storyblok = new StoryblokClient({
  accessToken: process.env.REACT_APP_STORYBLOK_TOKEN,
});

function Tutorials() {
  const [content, setContent] = useState(null);
  const params = useParams();
  const subDirectory = params.tutorialName;

  useEffect(() => {
    // Find the tutorial based on the subDirectory (slug)
    const tutorial = allTutorials.find((t) => t.slug === subDirectory);
    setContent(tutorial);
  }, [subDirectory]);

  const renderRichTextContent = (richTextData) => {
    const html = Storyblok.richTextResolver.render(richTextData);
    return { __html: html };
  };
  return (
    <>
      <Header
        title={content ? content.name : ""}
        image={bgImage}
        preview={bgImageSm}
        logoImage={content ? content.content.Logo : null}
        half
      />
      <Container sx={{ paddingTop: "32px" }}>
        <MKBox>
          {content && content.content.youtube_id && (
            <YoutubeEmbed embedId={content.content.youtube_id} />
          )}
          {content && (
            <div>
              <MKTypography variant="h5">{content.content.Header}</MKTypography>
              <div
                className="richTextWrapper"
                dangerouslySetInnerHTML={renderRichTextContent(content.content.Instructions)}
              />
            </div>
          )}
        </MKBox>
      </Container>
    </>
  );
}

export default Tutorials;
