// React and prop types
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

// Leaflet for maps
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

// Material-UI components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";

// Custom UI components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// MUI icon
import { Launch } from "@mui/icons-material";

// Static data and assets
import data from "../data/coords.json";
import crosshairSVG from "../assets/images/misc/crosshair.svg";
import HeaderBackground from "assets/images/misc/kudurru-frame.jpg";

const sortedData = data.combined;

function KudurruHeader({ title, subtitle, links, half }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [locationData, setLocationData] = useState({
    city: "",
    country: "",
    datetime: "",
  });
  const initialCoord = [
    parseFloat(sortedData[currentIndex].Lat),
    parseFloat(sortedData[currentIndex].Long),
  ];
  const markerRef = useRef(null);
  const infoDivRef = useRef(null);
  const [typedCityCountry, setTypedCityCountry] = useState("");
  const [typedDatetime, setTypedDatetime] = useState("");
  const [typedStatus, setTypedStatus] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [isFlyComplete, setIsFlyComplete] = useState(false);
  const [duration, setDuration] = useState(0);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  useEffect(() => {
    if (!isFlyComplete) return;

    const status = "AI Scraper Identified";
    let cityCountry = `${locationData.city}, ${locationData.country}`;
    if (locationData.city === locationData.country) {
      cityCountry = locationData.city;
    }

    let dateTime = new Date(locationData.datetime);
    let dateString = "";
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    dateString = `${months[dateTime.getMonth()]} ${dateTime.getDate()}, ${dateTime.getFullYear()}`;

    var hours = dateTime.getHours();
    var minutes = dateTime.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    dateString += ` ${hours}:${minutes} ${ampm}`;

    setTypedCityCountry(cityCountry);
    setTypedStatus(status);
    setTypedDatetime(dateString);
    setIsTyping(true);

    const timeoutId = setTimeout(() => {
      setIsTyping(false);
      // the duration needs to be set to match that of the animation
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
      setIsTyping(false);
    };
  }, [locationData, isFlyComplete]);

  useEffect(() => {
    // Use modulo operation to ensure that the future index loops back to the start
    let prevIndex = currentIndex - 1;
    if (prevIndex < 0) {
      prevIndex = sortedData.length - 1;
    }

    const prevCoord = [
      parseFloat(sortedData[prevIndex].Lat),
      parseFloat(sortedData[prevIndex].Long),
    ];
    const currentCoord = [
      parseFloat(sortedData[currentIndex].Lat),
      parseFloat(sortedData[currentIndex].Long),
    ];

    const pointA = L.latLng(prevCoord[0], prevCoord[1]);
    const pointB = L.latLng(currentCoord[0], currentCoord[1]);
    const distance = pointA.distanceTo(pointB) / 1000;

    setDuration(Math.min(Math.max(distance / 400, 0.5), 3));

    const city = sortedData[currentIndex].City;
    const country = sortedData[currentIndex].Country;
    const datetime = new Date(sortedData[currentIndex].Timestamp).toLocaleString();

    setTypedCityCountry("");
    setTypedDatetime("");
    setTypedStatus("");
    setLocationData({ city, country, datetime });
    setIsFlyComplete(false);
    setIsTyping(false);
  }, [currentIndex]);

  function MapInitializer() {
    const map = useMap();

    useEffect(() => {
      const svgIcon = L.icon({
        iconUrl: crosshairSVG,
        iconSize: [200, 200],
        iconAnchor: [100, 100],
      });

      markerRef.current = L.marker(initialCoord, { icon: svgIcon }).addTo(map);

      const updateInfoDivPosition = () => {
        if (markerRef.current && infoDivRef.current) {
          const position = map.latLngToContainerPoint(markerRef.current.getLatLng());
          infoDivRef.current.style.left = `${position.x + 70}px`;
          infoDivRef.current.style.top = `${position.y + 70}px`;
        }
      };

      const currentCoord = [
        parseFloat(sortedData[currentIndex].Lat),
        parseFloat(sortedData[currentIndex].Long),
      ];

      map
        .flyTo(currentCoord, 5, {
          duration: duration,
          animate: true,
          easeLinearity: 1.0,
          noMoveStart: false,
        })
        .once("moveend", () => setIsFlyComplete(true));

      if (markerRef.current) {
        markerRef.current.setLatLng(currentCoord);
        updateInfoDivPosition();
      }

      map.on("moveend", updateInfoDivPosition);

      const displayTimeInSeconds = 6;
      const intervalId = setTimeout(() => {
        const asyncCall = async () => {
          setCurrentIndex((prevIndex) => {
            const newIndex = (prevIndex + 1) % sortedData.length;
            return newIndex;
          });
        };
        asyncCall();
      }, displayTimeInSeconds * 1000);

      return () => {
        clearTimeout(intervalId);
        if (markerRef.current) {
          markerRef.current.remove();
        }
        map.off("moveend", updateInfoDivPosition);
      };
    }, [map]); // Add map and sortedData to dependency array

    return null;
  }

  useEffect(() => {
    if (isMobile) {
      const duration = 8;

      // kick off initial run
      const asyncCall = async () => {
        setCurrentIndex(0);
        setIsFlyComplete(true);
        await new Promise((r) => setTimeout(r, (duration - 1) * 1000));
      };
      asyncCall();

      const intervalId = setInterval(() => {
        const asyncCall = async () => {
          setCurrentIndex((prevIndex) => {
            const newIndex = (prevIndex + 1) % sortedData.length;
            return newIndex;
          });
          await new Promise((r) => setTimeout(r, 1000));
          setIsFlyComplete(true);
        };
        asyncCall();
      }, duration * 1000);

      return () => {
        clearTimeout(intervalId);
      };
    }
  }, [isMobile]);

  return (
    <MKBox
      minHeight={half || isMobile ? "55vh" : "100vh"}
      width="100%"
      margin="0"
      sx={{ display: "grid", placeItems: "end start" }}
    >
      {!isMobile ? (
        <span>
          <MapContainer
            center={initialCoord}
            zoom={5}
            style={{
              height: half ? "50vh" : "100vh",
              width: "100%",
              position: "absolute",
              top: 0,
              zIndex: 1,
              background: "black",
            }}
            dragging={false}
            touchZoom={false}
            doubleClickZoom={false}
            scrollWheelZoom={false}
            zoomControl={false}
          >
            <MapInitializer />
            <TileLayer
              url="https://{s}.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}{r}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
              subdomains="abcd"
              maxZoom={20}
            />
          </MapContainer>

          <div
            ref={infoDivRef}
            style={{
              position: "absolute",
              color: "white",
              padding: "10px",
              zIndex: 5,
            }}
          >
            <div className={isTyping ? "scraper-status typewriter-1" : "scraper-status"}>
              {typedStatus}
            </div>
            <div className={isTyping ? "scraper-blurb typewriter-2" : "scraper-blurb"}>
              {typedCityCountry}
            </div>
            <div className={isTyping ? "scraper-blurb typewriter-3" : "scraper-blurb"}>
              {typedDatetime}
            </div>
          </div>
        </span>
      ) : (
        <MKBox
          minHeight="60vh"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.5),
                rgba(gradients.dark.state, 0.5)
              )}, url(${HeaderBackground})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            display: "grid",
            placeItems: "end start",
            position: "absolute",
          }}
        >
          )
        </MKBox>
      )}

      <Container width="100%" sx={{ zIndex: 2, position: "relative" }}>
        <Grid
          container
          item
          xs={12}
          justifyContent={{ xs: "center", md: "start" }}
          sx={{ textAlign: { xs: "center", md: "left" } }}
        >
          <MKTypography
            variant="h1xl"
            color="white"
            mb={1}
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("md")]: { fontSize: size["3xl"] },
            })}
          >
            {title}
          </MKTypography>
          {!half && (
            <>
              <MKTypography
                variant="body1"
                color="white"
                width="100%"
                mt={1}
                pr={{ md: 12, lg: 12, xl: 12 }}
                opacity={0.9}
              >
                {subtitle}
              </MKTypography>
              <Stack direction="row" spacing={1} mt={6} mb={8}>
                {links.map(({ link, linkText, linkExt }, key) => (
                  <MKButton
                    key={link}
                    variant="outlined"
                    color="white"
                    mr={key === links.length - 1 ? 0 : 3}
                    mb={3}
                    href={link}
                    target={linkExt ? "_blank" : "_self"}
                  >
                    {linkText}
                    {linkExt ? <Launch /> : null}
                  </MKButton>
                ))}
              </Stack>
            </>
          )}
        </Grid>
      </Container>
    </MKBox>
  );
}

KudurruHeader.defaultProps = {
  title: "Default Title",
  subtitle: "Default Subtitle",
  links: [],
  half: false,
};

KudurruHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  links: PropTypes.array,
  half: PropTypes.bool,
};

export default KudurruHeader;
