// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Components
import MKBox from "components/MKBox";
import { Link } from "@mui/material";

// Images
import huggingface from "assets/images/logos/huggingface.svg";
import stability from "assets/images/logos/stability.svg";
import shutterstock from "assets/images/logos/shutterstock.svg";
import artstation from "assets/images/logos/artstation.svg";

function Logos() {
  return (
    <MKBox pt={4} pb={6}>
      <Container>
        <Grid container spacing={3} sx={{ px: { xs: 2, md: 4, lg: 8 } }}>
          <Grid item xs={6} lg={3}>
            <Link href={"https://huggingface.co"} target="_blank">
              <MKBox
                component="img"
                src={huggingface}
                alt="huggingface"
                width="100%"
                opacity={0.9}
              />
            </Link>
          </Grid>
          <Grid item xs={6} lg={3}>
            <Link
              href={"https://stability.ai"}
              target="_blank"
              rel="noreferrer noopener"
              aria-label={`https://stability.ai, opens in a new tab`}
            >
              <MKBox component="img" src={stability} alt="stability" width="100%" opacity={0.9} />
            </Link>
          </Grid>
          <Grid item xs={6} lg={3}>
            <Link
              href={"https://shutterstock.com"}
              target="_blank"
              rel="noreferrer noopener"
              aria-label={`https://shutterstock.com, opens in a new tab`}
            >
              <MKBox
                component="img"
                src={shutterstock}
                alt="shutterstock"
                width="100%"
                opacity={0.9}
              />
            </Link>
          </Grid>
          <Grid item xs={6} lg={3}>
            <Link
              href={"https://artstation.com"}
              target="_blank"
              rel="noreferrer noopener"
              aria-label={`https://artstation.com, opens in a new tab`}
            >
              <MKBox component="img" src={artstation} alt="artstation" width="100%" opacity={0.9} />
            </Link>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Logos;
