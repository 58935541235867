// Sections
import Header from "sections/Header";

// Components
import SEO from "components/SEO";

// Images
import bgImage from "assets/images/headers/optimized/creativefaq.jpg";
import bgImageSm from "assets/images/headers/preloads/creativefaq.jpg";
import Container from "@mui/material/Container";

import MKBox from "components/MKBox";
import { privacyPageContent } from "utils/privacyPageContent";
import parse from "html-react-parser";
import MKTypography from "components/MKTypography";

function PrivacyPolicy() {
  return (
    <>
      <SEO
        title="Privacy Policy for spawning.ai"
        description="Privacy Policy for Spawning's website."
      />
      <Header title="Privacy Policy" image={bgImage} preview={bgImageSm} half />
      <Container
        sx={{
          paddingTop: "32px",
        }}
      >
        <MKBox display="grid" gridTemplateColumns="40% 60%">
          {privacyPageContent.map((section, index) => (
            <>
              <MKBox key={index} padding="1rem">
                <MKBox id={index + 1}>
                  <MKTypography variant="h3">{section.title}</MKTypography>
                </MKBox>
              </MKBox>
              <MKBox
                key={index}
                padding="1rem"
                sx={{
                  whiteSpace: "pre-line",
                  ul: {
                    marginLeft: "24px",
                  },
                  a: {
                    textDecoration: "underline !important",
                    color: "#000",
                  },
                }}
              >
                {parse(`${section.content}`)}
              </MKBox>
            </>
          ))}
        </MKBox>
      </Container>
    </>
  );
}

export default PrivacyPolicy;
