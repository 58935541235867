// react-router-dom components
import { Link, useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import SpawningArrow from "assets/icons/SpawningArrow";

function DetailedFooter({ content }) {
  const { brand, socials, menus, copyright } = content;
  const { pathname } = useLocation();

  const ArrowRight = () => (
    <MKBox
      sx={{
        display: "flex",
        alignItems: "center",
        marginLeft: "-12px",

        svg: {
          width: "8px",
          height: "8px",
        },
      }}
    >
      <SpawningArrow />
    </MKBox>
  );

  return (
    <MKBox component="footer" pt={2} px={1} mt={4}>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4} mb={{ xs: 6, lg: 0 }}>
            <MKBox
              component="img"
              sx={{ height: 26 }}
              alt="Spawning logo next to the word Spawning"
              src={brand.image}
            />
            <MKTypography variant="body2" color="text" mb={3} pb={1} pr={3}>
              {brand.description}
            </MKTypography>
            <MKBox
              display="flex"
              alignItems="center"
              component="ul"
              p={0}
              m={0}
              sx={{ listStyle: "none" }}
            >
              {socials.map(({ icon, link, altText }, key) => (
                <MKBox component="li" p={0} m={0} lineHeight={1.25}>
                  <MKTypography
                    key={link}
                    component="a"
                    href={link}
                    target="_blank"
                    rel="noreferrer noopener"
                    aria-label={`${link}, opens in a new tab`}
                    variant="body2"
                    color="secondary"
                    mr={key === socials.length - 1 ? 0 : 3}
                    sx={{ fontSize: "1.125rem" }}
                    alt={altText}
                  >
                    {icon}
                  </MKTypography>
                </MKBox>
              ))}
            </MKBox>
          </Grid>
          {menus.map(({ name: title, items }, key) => (
            <Grid key={title} item xs={6} md={2} ml={key === 0 ? { xs: 0, lg: "auro" } : 0}>
              <MKTypography
                component="p"
                variant="navbar"
                fontWeight="bold"
                textTransform="capitalize"
                mb={1}
              >
                {title}
              </MKTypography>
              <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                {items.map(({ name, route, href }) => {
                  const isActive = pathname === route;
                  return (
                    <MKBox key={name} component="li" p={0} m={0} lineHeight={1.25}>
                      {href ? (
                        <MKTypography
                          component="a"
                          href={href}
                          target="_blank"
                          variant="button"
                          color="secondary"
                          fontWeight="regular"
                          display="flex"
                          alignItems="center"
                          gap="4px"
                          textTransform="capitalize"
                          pb={0.5}
                          rel="noreferrer noopener"
                          aria-label={`${href}, opens in a new tab`}
                        >
                          {name}
                        </MKTypography>
                      ) : (
                        <MKTypography
                          component={Link}
                          to={route}
                          variant="button"
                          display="flex"
                          alignItems="center"
                          gap="4px"
                          color="secondary"
                          fontWeight="regular"
                          textTransform="capitalize"
                          pb={0.5}
                        >
                          {isActive && <ArrowRight />}
                          {name}
                        </MKTypography>
                      )}
                    </MKBox>
                  );
                })}
              </MKBox>
            </Grid>
          ))}
          <Grid item xs={12} pr={3} sx={{ textAlign: "center", mb: 3 }}>
            <Divider />
            {copyright}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

// Typechecking props for the DetailedFooter
DetailedFooter.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default DetailedFooter;
