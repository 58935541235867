import React, { useState } from "react";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import { useForm, Controller } from "react-hook-form";
import MKTypography from "components/MKTypography";
import { Modal } from "@mui/material";
import LottieAnimation from "components/Lottie/LottieAnimation";

const asana = require("asana");
const client = asana.Client.create().useAccessToken(process.env.REACT_APP_ASANA_ACCESS_TOKEN);

const WORKSPACE_GID = process.env.REACT_APP_ASANA_WORKSPACE_GID;
const PROJECT_GID = process.env.REACT_APP_ASANA_WORKSPACE_GID;
const SECTION_GID = process.env.REACT_APP_ASANA_WEBSITE_INCOMING_SECTION_GID;

const ContactForm = () => {
  const [wasSubmitSuccessful, setWasSubmitSuccessful] = useState(true);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      comments: "",
    },
  });

  const onFormSubmit = async (data) => {
    const { email, comments } = data;
    setIsPending(true);

    // Parse the query string parameters
    const queryParams = new URLSearchParams(window.location.search);
    const queryString = Array.from(queryParams.entries())
      .map(([key, value]) => `${key}: ${value}`)
      .join(", ");

    try {
      const returnedTaskData = await client.tasks.createTask({
        workspace: WORKSPACE_GID,
        data: {
          name: "Site Contact Form",
          projects: PROJECT_GID,
        },
        assignee: "jordan@spawning.ai",
        name: email,
        notes: `email: ${email}  \n comments: ${comments} \n params: ${queryString}`,
      });

      console.log("returnedTaskData", returnedTaskData);

      client.sections.addTaskForSection(SECTION_GID, {
        task: returnedTaskData.gid,
      });

      setWasSubmitSuccessful(true);
    } catch (error) {
      console.error("error", error.message);
      setWasSubmitSuccessful(false);
    } finally {
      reset();
      setIsPending(false);
      setIsConfirmationModalOpen(true);
    }
  };

  if (isConfirmationModalOpen) {
    return (
      <Modal
        open={true}
        onClose={() => setIsConfirmationModalOpen(false)}
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(199, 200, 203, 0.33)",
          backdropFilter: "blur(5px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          zIndex: 3000,
        }}
      >
        <MKBox
          sx={{
            backgroundColor: "#fff",
            padding: "40px",
            minWidth: "300px",
            maxWidth: "600px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
            borderRadius: "10px",
            zIndex: 9000,
          }}
        >
          <MKTypography variant="h5" fontWeight="bold" textTransform="uppercase">
            {wasSubmitSuccessful ? "Thank you for your message!" : "Error"}
          </MKTypography>
          <MKTypography variant="body1">
            {wasSubmitSuccessful
              ? "We will get back to you soon."
              : "Sorry, we encountered an error. Please contact us directly at info@spawning.ai"}
          </MKTypography>
          <MKButton
            variant="gradient"
            color="light"
            fullWidth
            onClick={() => setIsConfirmationModalOpen(false)}
          >
            Close
          </MKButton>
        </MKBox>
      </Modal>
    );
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <LottieAnimation isVisible={isPending} />
      <MKBox
        sx={{
          padding: "20px",
          width: "100%",
          maxWidth: "800px",
          margin: "auto",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="email"
              rules={{ required: "Email is required" }}
              render={({ field }) => (
                <MKInput
                  {...field}
                  type="email"
                  label="Email Address"
                  fullWidth
                  error={!!errors.email}
                  helperText={errors.email ? errors.email.message : ""}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="comments"
              rules={{ required: "Comments are required" }}
              render={({ field }) => (
                <MKInput
                  {...field}
                  type="text"
                  label="Comments"
                  fullWidth
                  multiline
                  rows={4}
                  error={!!errors.comments}
                  helperText={errors.comments ? errors.comments.message : ""}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container item justifyContent="center" xs={12} my={6}>
          <MKButton
            type="submit"
            variant="contained"
            color="primary"
            sx={{ fontSize: "18px", ml: "auto" }}
          >
            Submit
          </MKButton>
        </Grid>
      </MKBox>
    </form>
  );
};

export default ContactForm;
