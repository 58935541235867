import { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { HelmetProvider } from "react-helmet-async";

// routes
import routes from "routes";
import footerRoutes from "footer.routes";

// theme & sections
import theme from "assets/theme";
import Navbar from "sections/Navbar";
import Footer from "sections/Footer";

// load pages
import HomePage from "pages/Home";
import PrivacyPolicy from "pages/PrivacyPolicy";
import Tutorials from "pages/Creatives/Tutorials";
import Contact from "pages/Contact";
import ImageCredits from "pages/ImageCredits";

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Navbar routes={routes} sticky light />
        <Routes>
          {/** handling redirects from existing sites in here */}
          {getRoutes(routes)}
          <Route path="/" element={<HomePage />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/About-1" element={<Navigate to="/about" />} />
          <Route path="/Team-1" element={<Navigate to="/about" />} />
          <Route path="/Press-2" element={<Navigate to="/about" />} />
          <Route path="/FAQ-1" element={<Navigate to="/about" />} />
          <Route path="/privacy" element={<Navigate to="/privacy-policy" />} />
          <Route path="/privacypolicy" element={<Navigate to="/privacy-policy" />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/tutorials/:tutorialName" element={<Tutorials />} />
          <Route path="/image-credits" element={<ImageCredits />} />

          <Route path="/spawning-ai-txt" element={<Navigate to="/ai-txt" />} />
        </Routes>
        <Footer content={footerRoutes} />
      </ThemeProvider>
    </HelmetProvider>
  );
}
