// Sections
import Header from "sections/Header";
import Faq from "sections/Faq";

// Components
import SEO from "components/SEO";

// Images
import bgImage from "assets/images/headers/optimized/developerfaq.jpg";
import bgImageSm from "assets/images/headers/preloads/developerfaq.jpg";

function ImageCredits() {
  return (
    <>
      <SEO
        title="Image credits for spawning.ai"
        description="Image credits for Spawning's website."
      />
      <Header title="Image Credits" image={bgImage} preview={bgImageSm} half />
      <Faq
        questions={[
          {
            question: "Leadership Team Images",
            answer:
              "The images of our leadership team were drawn by <a href='https://www.irisluckhaus.de' target='_blank;'>Iris Luckhaus</a>. Iris was a pleasure to work with and we couldn't be happier with how they turned out! We highly recommend her for commercial projects.",
          },
          {
            question: "Header Images",
            answer:
              "Our header images were generated using Manoloide's fantastic <a href='https://github.com/manoloide/AllSketchs' target='_blank;'>repository</a> of MIT-licensed <a href='https://processing.org' target='_blank;'>Processing</a> sketches.",
          },
        ]}
      />
    </>
  );
}

export default ImageCredits;
