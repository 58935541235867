import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import ColoredBackgroundCard from "components/Cards/BackgroundCards/ColoredBackgroundCard";

// HelpCenter page components
import ListItem from "components/ListItem";

function Card({ card }) {
  return (
    <ColoredBackgroundCard
      color="light"
      image={card.image}
      title={card.title}
      label={card.label}
      description={card.description}
      action={card.action}
    />
  );
}

// Typechecking props for the Card
Card.propTypes = {
  card: PropTypes.object,
};

function List({ list }) {
  return (
    <>
      {list.map(({ title, description }, key) => (
        <ListItem key={key} title={title}>
          {description}
        </ListItem>
      ))}
    </>
  );
}

// Typechecking props for the Card
List.propTypes = {
  list: PropTypes.array,
};

function CardList({ swap, card, list }) {
  return (
    <MKBox component="section" py={4}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={swap ? 5 : 4} sx={{ ml: "auto" }}>
            {swap ? <List list={list} /> : <Card card={card} />}
          </Grid>
          <Grid item xs={12} md={swap ? 4 : 5} sx={{ mr: "auto", ml: { xs: 0, md: 6 } }}>
            {swap ? <Card card={card} /> : <List list={list} />}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

// Setting default values for the props of HeaderHalf
CardList.defaultProps = {
  swap: false,
  card: {},
  list: [],
};

// Typechecking props for the HeaderHalf
CardList.propTypes = {
  swap: PropTypes.bool,
  card: PropTypes.object,
  list: PropTypes.array,
};

export default CardList;
