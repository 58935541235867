import React from "react";
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

const YoutubeEmbed = ({ embedId }) => (
  <MKBox component="section" pt={4} pb={6} width="100%">
    <Container>
      <Grid container item xs={12} lg={10} flexDirection="column" textAlign="center" mx="auto">
        <iframe
          width="100%"
          style={{ aspectRatio: 16 / 9 }}
          src={`https://www.youtube.com/embed/${embedId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </Grid>
    </Container>
  </MKBox>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default YoutubeEmbed;
