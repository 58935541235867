import React, { useState } from "react";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import { useForm, Controller } from "react-hook-form";
import MKTypography from "components/MKTypography";
import { Modal } from "@mui/material";
import { inputLabelClasses } from "@mui/material/InputLabel";
import LottieAnimation from "components/Lottie/LottieAnimation";

const asana = require("asana");
const client = asana.Client.create().useAccessToken(process.env.REACT_APP_ASANA_ACCESS_TOKEN);

const WORKSPACE_GID = process.env.REACT_APP_ASANA_WORKSPACE_GID;
const PROJECT_GID = process.env.REACT_APP_ASANA_KUDURRU_PROJECT_GID;
const SECTION_GID = process.env.REACT_APP_ASANA_KUDURRU_INCOMING_SECTION_GID;

const SignUpForm = () => {
  const [wasSubmitSuccessful, setWasSubmitSuccessful] = useState(true);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
      website: "",
    },
  });

  const handleFormSubmit = async () => {
    const { email, website } = getValues();

    setIsPending(true);

    try {
      const returnedTaskData = await client.tasks.createTask({
        workspace: WORKSPACE_GID,
        data: {
          name: "Kudurru Beta Sign-Ups",
          projects: PROJECT_GID,
        },
        assignee: "jordan@spawning.ai",
        name: email,
        notes: `email: ${email}  \n website: ${website}  `,
      });

      client.sections.addTaskForSection(SECTION_GID, {
        task: returnedTaskData.gid,
      });

      await setWasSubmitSuccessful(true);
      await reset();

      setIsPending(false);
      await setIsConfirmationModalOpen(true);
    } catch (error) {
      setIsPending(false);

      setWasSubmitSuccessful(false);
    }
  };

  if (isConfirmationModalOpen) {
    return (
      <Modal
        open={true}
        onClose={() => setIsConfirmationModalOpen(false)}
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(199, 200, 203, 0.33)",
          backdropFilter: "blur(5px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          zIndex: 3000,
        }}
      >
        <MKBox
          sx={{
            backgroundColor: "#fff",
            padding: "40px",
            minWidth: "300px",
            maxWidth: "600px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
            borderRadius: "10px",
            zIndex: 9000,
          }}
        >
          <MKTypography
            variant="h5"
            // color="white"
            fontWeight="bold"
            textTransform="uppercase"
          >
            Thank you for signing up for the Kudurru beta!
          </MKTypography>
          <MKTypography variant="body1">
            We&apos;ll review your website and be in touch soon to confirm your enrollment.
          </MKTypography>
          <MKButton
            variant="gradient"
            color="light"
            fullWidth
            onClick={() => setIsConfirmationModalOpen(false)}
          >
            Close
          </MKButton>
        </MKBox>
      </Modal>
    );
  }

  return (
    <>
      <LottieAnimation isVisible={isPending} />
      <MKBox
        sx={{
          border: "1px solid #fff",
          padding: "20px",
        }}
      >
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MKBox>
                <Controller
                  control={control}
                  name="email"
                  type="email"
                  required
                  render={({ field }) => (
                    <MKInput
                      id="email"
                      variant="standard"
                      type="email"
                      label="Email Address"
                      fullWidth
                      error={!!errors.email}
                      helperText={errors.email?.message}
                      sx={{
                        p: {
                          "> .MuiFormHelperText-root": {
                            color: "red",
                          },
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          color: "#fff",
                          [`.${inputLabelClasses.shrink}`]: {
                            color: "#fff",
                          },
                          [`&.${inputLabelClasses.animated}`]: {
                            color: "#fff",
                            [`&.Mui-focused`]: {
                              color: "#fff",
                              borderColor: "#fff",
                            },
                            ["~ .MuiInputBase-root"]: {
                              color: "#fff",
                              borderColor: "#fff",
                            },
                            ["~ .MuiInput-root:after"]: {
                              color: "#fff",
                              borderColor: "#fff",
                            },
                            "&:before": {
                              borderColor: "#fff",
                            },
                            "&:after": {
                              borderColor: "#fff",
                            },
                          },
                        },
                      }}
                      {...field}
                    />
                  )}
                  rules={{
                    required: "Please enter a valid email",
                  }}
                />
              </MKBox>
            </Grid>
            <Grid item xs={12}>
              <MKBox>
                <Controller
                  control={control}
                  name="website"
                  render={({ field }) => (
                    <MKInput
                      id="website"
                      variant="standard"
                      type="website"
                      label="Website Address"
                      fullWidth
                      error={!!errors.website}
                      helperText={errors.website?.message}
                      sx={{
                        p: {
                          "> .MuiFormHelperText-root": {
                            color: "red",
                          },
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          color: "#fff",
                          [`.${inputLabelClasses.shrink}`]: {
                            color: "#fff",
                          },
                          [`&.${inputLabelClasses.animated}`]: {
                            color: "#fff",
                            [`&.Mui-focused`]: {
                              color: "#fff",
                              borderColor: "#fff",
                            },
                            ["~ .MuiInputBase-root"]: {
                              color: "#fff",
                              borderColor: "#fff",
                            },
                            ["~ .MuiInput-root:after"]: {
                              color: "#fff",
                              borderColor: "#fff",
                            },
                            "&:before": {
                              borderColor: "#fff",
                            },
                            "&:after": {
                              borderColor: "#fff",
                            },
                          },
                        },
                      }}
                      helperText={errors?.website?.message || ""}
                      {...field}
                    />
                  )}
                  rules={{
                    required: "Please enter a valid website address",
                  }}
                />
              </MKBox>
            </Grid>
          </Grid>

          <Grid container item justifyContent="center" xs={12} my={6} mb="18px">
            <MKButton
              variant="gradient"
              color="light"
              fullWidth
              sx={{
                fontSize: "18px",
              }}
              type="submit"
              disabled={isPending || errors.email || errors.website}
            >
              {wasSubmitSuccessful ? "Sign up" : "Try Again"}
            </MKButton>
          </Grid>
        </form>
      </MKBox>
    </>
  );
};

export default SignUpForm;
