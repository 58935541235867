import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import HorizontalTeamCard from "components/Cards/TeamCards/HorizontalTeamCard";

function Team({ cards }) {
  return (
    <MKBox component="section" pt={4} pb={6}>
      <Container>
        <Grid container spacing={0} justifyContent={"center"}>
          {cards.map(({ image, name, position, description }, key) => (
            <Grid item key={key} xs={6} lg={4}>
              <HorizontalTeamCard
                image={image}
                name={name}
                position={{ color: "info", label: position }}
                description={description}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

// Setting default values for the props of Team
Team.defaultProps = {
  cards: [],
};

// Typechecking props for the Team
Team.propTypes = {
  cards: PropTypes.array,
};

export default Team;
