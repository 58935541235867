// @mui icons
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";

// Images
import SpawningLogoPng from "assets/images/logos/spawning-logo.png";
import PrivacyPolicy from "pages/PrivacyPolicy";
import ImageCredits from "pages/ImageCredits";

const date = new Date().getFullYear();

const footerRoutes = {
  brand: {
    name: "Spawning",
    description: "Data Governance for Generative AI",
    image: SpawningLogoPng,
    route: "/",
  },
  socials: [
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/spawning_",
      altText: "Spawning on Twitter",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/@spawningAI",
      altText: "Spawning on YouTube",
    },
    {
      icon: <GitHubIcon />,
      link: "https://github.com/Spawning-Inc",
      altText: "Spawning on GitHub",
    },
    {
      icon: <LinkedInIcon />,
      link: "https://www.linkedin.com/company/spawning/",
      altText: "Spawning on LinkedIn",
    },
  ],
  menus: [
    {
      name: "Creatives",
      items: [
        {
          name: "Have I Been Trained?",
          route: "/have-i-been-trained",
          route: "/have-i-been-trained",
        },
        {
          name: "Kudurru",
          route: "/kudurru",
        },
        {
          name: "Browser Extension",
          route: "/browser-extension",
          route: "/browser-extension",
        },
        {
          name: "ai.txt",
          route: "/ai-txt",
          route: "/ai-txt",
        },
        {
          name: "Creatives FAQ",
          route: "/creatives-faq",
          route: "/creatives-faq",
        },
      ],
    },
    {
      name: "Developers",
      items: [
        {
          name: "Spawning API",
          route: "/spawning-api",
          route: "/spawning-api",
        },
        {
          name: "Data Diligence",
          route: "/data-diligence",
          route: "/data-diligence",
        },
        {
          name: "API Docs \u21D7",
          href: "https://datadiligence.readthedocs.io/en/latest/quickstart.html",
        },
        {
          name: "Developers FAQ",
          route: "/developers-faq",
          route: "/developers-faq",
        },
      ],
    },
    {
      name: "company",
      items: [
        {
          name: "About",
          route: "/about",
        },
        {
          name: "Blog \u21D7",
          href: "https://spawning.substack.com",
        },
        {
          name: "Contact",
          href: "/contact",
        },
      ],
    },
    {
      name: "Website",
      items: [
        { name: "image credits", route: "/image-credits", component: <ImageCredits /> },
        // { name: "terms of service", href: "https://site.spawning.ai/terms-of-service?ctx=ai-txt" },
        {
          name: "Privacy Policy",
          route: "/privacy-policy",
          component: <PrivacyPolicy />,
        },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} by{" "}
      <MKTypography
        component="a"
        href="https://spawning.ai"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Spawning
      </MKTypography>
      .
    </MKTypography>
  ),
};

export default footerRoutes;
