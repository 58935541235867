import { useRef } from "react";

// SwiperJS
import SwiperCore, { Navigation } from "swiper";

// SwiperJS react components
import { Swiper, SwiperSlide } from "swiper/react";

// SwiperJS styles
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.css";

// @mui material components
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import ComplexNewsCard from "components/Cards/NewsCards/ComplexNewsCard";

// Images
import news1 from "assets/images/news/newyorker.webp";
import news2 from "assets/images/news/npr.webp";
import news3 from "assets/images/news/wired.webp";

function News() {
  // install SwiperJS modules
  SwiperCore.use([Navigation]);

  // Swiper navigation buttons styles
  const navigationStyles = {
    position: "absolute",
    top: 0,
    zIndex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "15%",
    height: "100%",
    textAlign: "center",
    opacity: 0.5,
    cursor: "pointer",
    transition: "opacity 0.15s ease",

    "&:hover, &:focus": {
      opacity: 1,
    },
  };

  // SwiperJS navigation buttons ref
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <MKBox position="relative" pt={4} sx={{ pb: { lg: 6 } }}>
      <Swiper
        onInit={({ params, navigation }) => {
          const { navigation: nav } = params;

          nav.prevEl = navigationPrevRef.current;
          nav.nextEl = navigationNextRef.current;
          navigation.init();
          navigation.update();
        }}
        spaceBetween={0}
        slidesPerView={1}
        loop
      >
        <SwiperSlide>
          <Container>
            <ComplexNewsCard
              image={news1}
              link="https://www.newyorker.com/magazine/2023/11/20/holly-herndons-infinite-art"
              outlet="The New Yorker &nbsp;|&nbsp; Nov 13, 2023"
              title="Holly Herndon’s Infinite Art"
              quote="The artist and musician uses machine learning to make strange, playful work. She also advocates for artists’ autonomy in a world shaped by A.I."
            />
          </Container>
        </SwiperSlide>
        <SwiperSlide>
          <Container>
            <ComplexNewsCard
              image={news2}
              link="https://www.npr.org/2023/11/03/1210208164/new-tools-help-artists-fight-ai-by-directly-disrupting-the-systems"
              outlet="NPR &nbsp;|&nbsp; Nov 3, 2023"
              title="New tools help artists fight AI by directly disrupting the systems"
              quote="[Kudurru], now in beta, tracks scrapers' IP addresses and blocks them or sends back unwanted content, such as an extended middle finger, or the classic 'Rickroll' Internet trolling prank"
            />
          </Container>
        </SwiperSlide>
        <SwiperSlide>
          <Container>
            <ComplexNewsCard
              image={news3}
              link="https://www.wired.com/story/kudurru-ai-scraping-block-poisoning-spawning/"
              outlet="Wired &nbsp;|&nbsp; Oct 12, 2023"
              title="A New Tool Helps Artists Thwart AI—With a Middle Finger"
              quote="Kudurru, the new tool from the creator of Have I Been Trained?, can help artists block web scrapers and even “poison” the scraping by sending back the wrong image."
            />
          </Container>
        </SwiperSlide>
        <MKTypography
          variant="div"
          color="dark"
          sx={{
            ...navigationStyles,
            left: 0,
          }}
          ref={navigationPrevRef}
        >
          <Icon>chevron_left</Icon>
        </MKTypography>
        <MKTypography
          variant="div"
          color="dark"
          sx={{
            ...navigationStyles,
            right: 0,
          }}
          ref={navigationNextRef}
        >
          <Icon>chevron_right</Icon>
        </MKTypography>
      </Swiper>
    </MKBox>
  );
}

export default News;
