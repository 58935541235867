import PropTypes from "prop-types";

// @mui material components
// import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
// import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function SectionTitle({ pretitle, title, subtitle }) {
  return (
    <>
      <Grid
        container
        item
        xs={12}
        lg={8}
        flexDirection="column"
        textAlign="center"
        mx="auto"
        pt={4}
      >
        <MKTypography
          variant="body2"
          color="secondary"
          fontWeight="bold"
          textTransform="uppercase"
          textGradient
        >
          {pretitle}
        </MKTypography>
        <MKTypography variant="h1">{title}</MKTypography>
        <MKTypography
          variant="body2"
          color="secondary"
          fontWeight="bold"
          textTransform="uppercase"
          textGradient
        >
          {subtitle}
        </MKTypography>
      </Grid>
    </>
  );
}

// Setting default values for the props of HeaderHalf
SectionTitle.defaultProps = {
  pretitle: "",
  title: "",
  subtitle: "",
};

// Typechecking props for the HeaderHalf
SectionTitle.propTypes = {
  pretitle: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default SectionTitle;
