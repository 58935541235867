import React from "react";

const ImagesIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="#CFD5FF" />
    <path
      d="M23.5 27.5H15.7761C15.2713 27.5 15.0189 27.5 14.902 27.4002C14.8006 27.3135 14.7467 27.1836 14.7572 27.0506C14.7693 26.8974 14.9478 26.7189 15.3047 26.3619L22.3905 19.2761C22.7205 18.9461 22.8855 18.7811 23.0758 18.7193C23.2432 18.6649 23.4235 18.6649 23.5908 18.7193C23.7811 18.7811 23.9461 18.9461 24.2761 19.2761L27.5 22.5V23.5M23.5 27.5C24.9001 27.5 25.6002 27.5 26.135 27.2275C26.6054 26.9878 26.9878 26.6054 27.2275 26.135C27.5 25.6002 27.5 24.9001 27.5 23.5M23.5 27.5H16.5C15.0999 27.5 14.3998 27.5 13.865 27.2275C13.3946 26.9878 13.0122 26.6054 12.7725 26.135C12.5 25.6002 12.5 24.9001 12.5 23.5V16.5C12.5 15.0999 12.5 14.3998 12.7725 13.865C13.0122 13.3946 13.3946 13.0122 13.865 12.7725C14.3998 12.5 15.0999 12.5 16.5 12.5H23.5C24.9001 12.5 25.6002 12.5 26.135 12.7725C26.6054 13.0122 26.9878 13.3946 27.2275 13.865C27.5 14.3998 27.5 15.0999 27.5 16.5V23.5M18.75 17.0833C18.75 18.0038 18.0038 18.75 17.0833 18.75C16.1629 18.75 15.4167 18.0038 15.4167 17.0833C15.4167 16.1629 16.1629 15.4167 17.0833 15.4167C18.0038 15.4167 18.75 16.1629 18.75 17.0833Z"
      stroke="#707070"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ImagesIcon;
