import React, { useState, useEffect } from "react";
import MKBox from "components/MKBox";
import Toggle from "components/Toggle/Toggle";
import { aiTxtOptions } from "utils/aiTxtOptions";
import { determineAllowedTypes, determineBlockedTypes, handleDownload } from "./helpers";
import MKButton from "components/MKButton";

import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import { Container, Grid, Table } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import MKTypography from "components/MKTypography";

const AiTxtGenerator = () => {
  const [aiTxtGeneratorOptions, setAiTxtGeneratorOptions] = useState(aiTxtOptions);
  const [blockedTypes, setBlockedTypes] = useState([]);
  const [allowedTypes, setAllowedTypes] = useState([]);

  useEffect(() => {
    const blockedOptions = determineBlockedTypes(aiTxtGeneratorOptions);
    const allowedOptions = determineAllowedTypes(aiTxtGeneratorOptions);

    setBlockedTypes(blockedOptions);
    setAllowedTypes(allowedOptions);
  }, [aiTxtGeneratorOptions]);

  const handleOnToggle = (name, isBlocked) => {
    const newArr = aiTxtGeneratorOptions.map((option) =>
      option.name === name ? { ...option, isBlocked: !isBlocked } : option
    );

    setAiTxtGeneratorOptions(newArr);
  };

  return (
    <MKBox component="section" pt={4} pb={6} width="100%">
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Grid container item xs={12} lg={10} mx="auto">
          <TableContainer>
            <MuiTable>
              <MKBox component="thead">
                <TableRow>
                  <MKBox
                    key="empty"
                    component="th"
                    width="auto"
                    pt={1.5}
                    pb={1.25}
                    pl={3}
                    pr={3}
                    textAlign="center"
                    color="secondary"
                    sx={({
                      typography: { size, fontWeightBold },
                      borders: { borderWidth, borderColor },
                    }) => ({
                      fontSize: size.xxs,
                      fontWeight: fontWeightBold,
                      borderBottom: `${borderWidth[1]} solid ${borderColor}`,
                    })}
                  ></MKBox>
                  <MKBox
                    key="block"
                    component="th"
                    width="auto"
                    pt={1.5}
                    pb={1.25}
                    pl={3}
                    pr={3}
                    textAlign="center"
                    color="secondary"
                    sx={({
                      typography: { size, fontWeightBold },
                      borders: { borderWidth, borderColor },
                    }) => ({
                      fontSize: size.xxs,
                      fontWeight: fontWeightBold,
                      borderBottom: `${borderWidth[1]} solid ${borderColor}`,
                    })}
                  >
                    BLOCK
                  </MKBox>
                  <MKBox
                    key="allow"
                    component="th"
                    width="auto"
                    pt={1.5}
                    pb={1.25}
                    pl={3}
                    pr={3}
                    textAlign="center"
                    color="secondary"
                    sx={({
                      typography: { size, fontWeightBold },
                      borders: { borderWidth, borderColor },
                    }) => ({
                      fontSize: size.xxs,
                      fontWeight: fontWeightBold,
                      borderBottom: `${borderWidth[1]} solid ${borderColor}`,
                    })}
                  >
                    ALLOW
                  </MKBox>
                </TableRow>
              </MKBox>
              <TableBody>
                {aiTxtGeneratorOptions.map((option) => {
                  const { name, isBlocked, icon } = option;

                  return (
                    <TableRow key={name}>
                      <TableCell>
                        <MKBox key={name + "-1"} component="td" p={0} textAlign="center">
                          <MKTypography
                            variant="button"
                            fontWeight="regular"
                            color="secondary"
                            sx={{ display: "inline-block", width: "max-content" }}
                          >
                            <MKBox
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                width: "100px",
                                gap: 1,
                              }}
                            >
                              {icon}

                              <MKTypography
                                as="span"
                                sx={{
                                  textTransform: "capitalize",
                                  fontSize: "18px",
                                }}
                              >
                                {name}
                              </MKTypography>
                            </MKBox>
                          </MKTypography>
                        </MKBox>
                      </TableCell>
                      <TableCell
                        key={name + "-2"}
                        component="td"
                        p={1}
                        style={{ textAlign: "center" }}
                      >
                        <Toggle
                          defaultChecked
                          checked={isBlocked}
                          onChange={() => {
                            handleOnToggle(name, isBlocked);
                          }}
                        />
                      </TableCell>
                      <TableCell
                        key={name + "-3"}
                        component="td"
                        p={1}
                        style={{ textAlign: "center" }}
                      >
                        <Toggle
                          checked={!isBlocked}
                          onChange={() => {
                            handleOnToggle(name, isBlocked);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </MuiTable>
          </TableContainer>
        </Grid>

        <MKBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 3,
          }}
        >
          <MKTypography variant="body2" color="text">
            By using the ai.txt generator, you agree to the{" "}
            <MKTypography
              component="a"
              onClick={() => {
                window.open("https://site.spawning.ai/terms-of-service?ctx=ai-txt", "_blank");
              }}
              sx={{
                textDecoration: "underline !important",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              variant="body2"
            >
              Terms of Service
            </MKTypography>
          </MKTypography>
          <MKButton
            variant="outlined"
            color="dark"
            onClick={() => {
              handleDownload(allowedTypes, blockedTypes);
            }}
          >
            Download ai.txt
            <DownloadIcon sx={{ ml: 1 }} />
          </MKButton>
        </MKBox>
      </Container>
    </MKBox>
  );
};

export default AiTxtGenerator;
