// Sections
import Header from "sections/Header";
import CardGrid from "sections/CardGrid";
import SectionTitle from "sections/SectionTitle";
import Faq from "sections/Faq";

// Components
import SEO from "components/SEO";
import MKBox from "components/MKBox";

// Images
import bgImage from "assets/images/headers/optimized/browserextension.jpg";
import bgImageSm from "assets/images/headers/preloads/browserextension.jpg";
import chromeImage from "assets/images/logos/chrome.png";
import firefoxImage from "assets/images/logos/firefox.png";
import edgeImage from "assets/images/logos/edge.png";

function BrowserExtension() {
  return (
    <>
      <SEO
        title="Spawning Browser Extension"
        description="As you surf the web, use our extension to add images, text, video, code, and more to our Do Not Train registry."
      />
      <Header
        image={bgImage}
        preview={bgImageSm}
        title="Spawning Browser Extension"
        subtitle="As you surf the web, use our extension to add images, text, video,
        code, and more to our Do Not Train registry."
        links={[
          {
            link: "#content",
            linkText: "learn more",
            linkExt: false,
          },
          {
            link: "#downloads",
            linkText: "Get the extension",
            linkExt: false,
          },
        ]}
      />
      <MKBox width="100%" id="content" pt={8} mt={-4}>
        <CardGrid
          cols={3}
          cardType="Info"
          cards={[
            {
              title: "Instant Insights",
              description:
                "Seamlessly uncover what has already been scraped from the web pages you browse. The Spawning extension flags items included in public AI training sets.",
              direction: "center",
            },
            {
              title: "All Media Types",
              description:
                "Add any media type to our Do Not Train registry. The Spawning extension will surface every link referenced on the pages you scan so you can opt them out.",
              direction: "center",
            },
            {
              title: "Secure and Private",
              description:
                "Only the URLs that you add to the Do Not Train registry are stored by Spawning's servers. Your saved scans are stored locally and can be easily cleared.",
              direction: "center",
            },
          ]}
        />
        <MKBox id="downloads" pt={8} mt={-8}>
          <SectionTitle pretitle="Spawning Browser Extension" title="Downloads" />
          <CardGrid
            cols={3}
            cardType="InfoBg"
            cards={[
              {
                pretitle: "Download for",
                title: "Google Chrome",
                image: chromeImage,
                link: "https://chrome.google.com/webstore/detail/spawning/gflllnclkhgldggflpajgmneddanojbo",
              },
              {
                pretitle: "Download for",
                title: "Mozilla Firefox",
                image: firefoxImage,
                link: "https://addons.mozilla.org/en-US/firefox/addon/spawning/",
              },
              {
                pretitle: "Download for",
                title: "Microsoft Edge",
                image: edgeImage,
                link: "https://microsoftedge.microsoft.com/addons/detail/spawning/dgdkbhplbigldcpeccpjidcjabodefkk",
              },
            ]}
          />
        </MKBox>
        <SectionTitle pretitle="Spawning Browser Extension" title="Getting Started" />
        <CardGrid
          cols={3}
          cardType="Info"
          cards={[
            {
              title: "Step 1: Install",
              description:
                "Start by installing the Spawning Extension into your browser. Visit the web store of your preferred browser (Chrome, Firefox, Safari*, Edge) and follow the on-screen instructions.",
              direction: "center",
            },
            {
              title: "Step 2: Configure",
              description:
                "With the extension installed, navigate to your browser’s extension settings to customize the extension to your needs. Select the types of media you want to surface and save those preferences.",
              direction: "center",
            },
            {
              title: "Step 3: Browse",
              description:
                "Browse the web as you normally would. When you arrive at a site of interest, open the Spawning Extension and click ‘Inspect’.",
              direction: "center",
            },
            {
              title: "Step 4: Capture",
              description:
                "Post-inspection, the extension provides a breakdown of the number of each media type present on the site. Click ‘View media’ to navigate to 'Have I Been Trained?' for a more detailed review of the identified media.",
              direction: "center",
            },
            {
              title: "Step 5: Analyze",
              description:
                "After redirecting to 'Have I Been Trained?', you can view and find similar images that have been included in popular datasets. ",
              direction: "center",
            },
            {
              title: "Step 6: Opt Out",
              description:
                "If any of the items identified are yours, you're empowered to take action. You can log in and add any item to Spawning's Do Not Train Registry.",
              direction: "center",
            },
          ]}
        />
        <SectionTitle pretitle="Spawning Browser Extension" title="Frequently Asked Questions" />
        <Faq
          questions={[
            {
              question: "What is the Browser Extension?",
              answer:
                "The Spawning Browser Extension is a tool that allows you to discover if the data on the web pages you browse has been included in public AI training sets. It provides insights, analytics, and control over your data usage.",
            },
            {
              question: "How does the Browser Extension Work?",
              answer:
                "After you install the extension and set your preferences, you can initiate a data inspection on any webpage. The extension searches for your specified media types on the page, checks if this media appears in public AI training sets, and provides you with a detailed report.",
            },
            {
              question: "Do I have to pay to use the Browser Extension?",
              answer:
                "As part of Spawning’s initiative to create better transparency in AI datasets, our Browser Extension is free to install and use.",
            },
            {
              question: "How do I install the Browser Extension?",
              answer:
                "You can install the Spawning Extension by clicking on the 'Get the extension’ button on our website and following the instructions or download it from the webstore for your preferred browser (Chrome, Firefox, Edge).",
            },
            {
              question: "What happens if I find that my work has been used without my consent?",
              answer:
                "If you discover your work is being used without your consent, you can choose to add it to our Do Not Train list, establishing clear boundaries for your data usage in AI training sets.",
            },
            {
              question: "What data does the Browser Extension store?",
              answer:
                "The Spawning Browser Extension is designed with your privacy in mind. The only data stored is your usage history, and it is stored locally within the extension, not on Spawning's servers. This history is fully under your control: you can search it, clear it, and it's only accessible to you. We respect your privacy and aim to provide you with full transparency and control over your data.",
            },
            {
              question: "How extensive is the Browser Extension's search across a webpage?",
              answer:
                "When you initiate a search with the Spawning Extension, it scans only what is currently loaded by the web page you are viewing. The extension does not delve into other pages within the same domain or the entire website. The search is focused on the specific page, providing you with insights on the data specified in your search filter configuration.",
            },
          ]}
        />
      </MKBox>
    </>
  );
}

export default BrowserExtension;
