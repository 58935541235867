import React from "react";

const TextIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="#CFD5FF" />
    <path
      d="M21.6667 11.8913V15.3334C21.6667 15.8001 21.6667 16.0335 21.7575 16.2117C21.8374 16.3685 21.9649 16.496 22.1217 16.5759C22.3 16.6667 22.5333 16.6667 23 16.6667H26.4422M26.6667 18.3236V24.3334C26.6667 25.7335 26.6667 26.4336 26.3942 26.9684C26.1545 27.4388 25.7721 27.8212 25.3017 28.0609C24.7669 28.3334 24.0668 28.3334 22.6667 28.3334H17.3334C15.9332 28.3334 15.2332 28.3334 14.6984 28.0609C14.228 27.8212 13.8455 27.4388 13.6059 26.9684C13.3334 26.4336 13.3334 25.7335 13.3334 24.3334V15.6667C13.3334 14.2666 13.3334 13.5666 13.6059 13.0318C13.8455 12.5614 14.228 12.1789 14.6984 11.9392C15.2332 11.6667 15.9332 11.6667 17.3334 11.6667H20.0099C20.6213 11.6667 20.9271 11.6667 21.2148 11.7358C21.4699 11.7971 21.7137 11.8981 21.9374 12.0351C22.1897 12.1898 22.4059 12.4059 22.8383 12.8383L25.4951 15.4952C25.9275 15.9276 26.1437 16.1437 26.2983 16.396C26.4354 16.6197 26.5364 16.8636 26.5976 17.1187C26.6667 17.4064 26.6667 17.7121 26.6667 18.3236Z"
      stroke="#707070"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TextIcon;
