import React from "react";

const CodeIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="#CFD5FF" />
    <path
      d="M24 26L30 20L24 14M16 14L10 20L16 26"
      stroke="#707070"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CodeIcon;
