import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import animationData from "assets/lottie/loading.json";

const LottieAnimation = ({ isVisible }) => {
  const lottieContainer = useRef(null);
  const animationInstance = useRef(null);

  useEffect(() => {
    if (!lottieContainer.current) return;

    animationInstance.current = lottie.loadAnimation({
      container: lottieContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: false, // Changed to false to manually control play
      animationData: animationData,
    });

    animationInstance.current.setSpeed(2.0);

    return () => animationInstance.current.destroy();
  }, []);

  useEffect(() => {
    if (!animationInstance.current) return;

    if (isVisible) {
      animationInstance.current.goToAndPlay(0, true);
    } else {
      animationInstance.current.stop();
    }
  }, [isVisible]);

  const containerStyle = {
    position: "fixed", // Fixed position
    top: "50%", // Center vertically
    left: "50%", // Center horizontally
    transform: "translate(-50%, -50%)", // Adjust for the element's own size
    width: "400px",
    height: "400px",
    display: isVisible ? "block" : "none", // Control visibility
    zIndex: 9999, // High z-index to stay on top
  };

  return <div ref={lottieContainer} style={containerStyle} />;
};

export default LottieAnimation;
